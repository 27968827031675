<template>
  <v-app>
    <v-main>
      <form>
        <v-container grid-list-md class="ghost">
          <v-row class="justify-space-between">
            <v-col cols="10" sm="3">
              <v-text-field
                label="Patient ID / Name"
                outlined
                class="iol-v-text-field"
                ref="first"
                v-model="inputs.patientID"
              />
            </v-col>
            <v-col cols="10" sm="3">
              <v-select
                label="Keratometric Index"
                outlined
                class="smooth iol-v-select"
                v-model="inputs.keratometricIndex"
                :items="$parent.keratometricItems"
                return-object
                required
              />
            </v-col>
            <v-col cols="10" sm="3">
              <v-select
                label="Biometer"
                :items="$parent.biometerItems"
                item-text="text"
                outlined
                class="smooth iol-v-select"
                v-model="inputs.biometer"
                return-object
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-sheet class="iol-v-sheet">
            <v-row>
              <v-col cols="12" md="6">
                <v-sheet class="iol-right-v-sheet">
                  <h3>OD</h3>
                  <v-row class="justify-md-space-between">
                    <v-col cols="6" md="3">
                      <v-select
                        label="IOL Model"
                        :items="$parent.IOLModelItems"
                        item-text="text"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs.IOLModel"
                        return-object
                        @change="IOLModelUpdated(inputs, inputs.IOLModel)"
                        @blur="IOLModelUpdated(inputs, inputs.IOLModel)"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        label="A constant"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.aconst"
                        :error-messages="getErrors('aconst', $v.inputs.aconst)"
                        :disabled="inputs.is60MA"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.aconst.$touch()"
                        @blur="$v.inputs.aconst.$touch()"
                        @change="checkRequired('OD', inputs.aconst)"
                      />
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        label="Target refraction (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.target"
                        :error-messages="getErrors('target', $v.inputs.target)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.target.$touch()"
                        @blur="$v.inputs.target.$touch()"
                        @change="checkRequired('OD', inputs.target)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="3">
                      <v-select
                        label="Vitreous type"
                        :items="$parent.vitreousItems"
                        item-text="type"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs.vitreousType"
                        return-object
                        readonly
                        @change="vitreousTypeUpdated('nvitOD', inputs.vitreousType)"
                        @blur="vitreousTypeUpdated('nvitOD', inputs.vitreousType)"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-tooltip bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Custom vitreous"
                            outlined
                            class="iol-v-text-field"
                            v-model="inputs.nvit"
                            :error-messages="getErrors('nvit', $v.inputs.nvit)"
                            :disabled="inputs.isVitreous"
                            type="number"
                            step="0.0001"
                            @input="$v.inputs.nvit.$touch()"
                            @blur="$v.inputs.nvit.$touch()"
                            @change="checkRequired('OD', inputs.nvit)"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>Enter the refractive index of the material (ex. silicon oil)</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="justify-space-between">
                    <v-col cols="6" md="5">
                      <v-text-field
                        label="AL (mm)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.AL"
                        :error-messages="getErrors('AL', $v.inputs.AL)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.AL.$touch()"
                        @blur="$v.inputs.AL.$touch()"
                        @change="checkRequired('OD', inputs.AL)"
                      />
                      <v-text-field
                        label="K1 (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.K1"
                        :error-messages="getErrors('K1', $v.inputs.K1)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K1.$touch()"
                        @blur="$v.inputs.K1.$touch()"
                        @change="checkRequired('OD', inputs.K1)"
                      />
                      <v-text-field
                        label="K2 (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.K2"
                        :error-messages="getErrors('K2', $v.inputs.K2)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K2.$touch()"
                        @blur="$v.inputs.K2.$touch()"
                        @change="checkRequired('OD', inputs.K2)"
                      />
                      <v-text-field
                        label="ACD (mm)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.ACD"
                        :error-messages="getErrors('ACD', $v.inputs.ACD)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.ACD.$touch()"
                        @blur="$v.inputs.ACD.$touch()"
                        @change="checkRequired('OD', inputs.ACD)"
                      />
                    </v-col>
                    <v-col cols="6" md="5">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-text-field
                          label="LT (mm)"
                          outlined
                          class="iol-v-text-field"
                          v-model="inputs.LT"
                          :error-messages="getErrors('LT', $v.inputs.LT)"
                          type="number"
                          step="0.01"
                          @input="$v.inputs.LT.$touch()"
                          @blur="$v.inputs.LT.$touch()"
                        />
                        <v-text-field
                          label="CCT (µm)"
                          outlined
                          class="iol-v-text-field"
                          v-model="inputs.CCT"
                          :error-messages="getErrors('CCT', $v.inputs.CCT)"
                          type="number"
                          step="0"
                          @input="$v.inputs.CCT.$touch()"
                          @blur="$v.inputs.CCT.$touch()"
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="iol-left-v-sheet">
                  <h3>OS</h3>
                  <v-row class="justify-md-space-between">
                    <v-col cols="6" md="3">
                      <v-select
                        label="IOL Model"
                        :items="$parent.IOLModelItems"
                        item-text="text"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs2.IOLModel"
                        return-object
                        @change="IOLModelUpdated(inputs2, inputs2.IOLModel)"
                        @blur="IOLModelUpdated(inputs2, inputs2.IOLModel)"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-text-field
                        label="A constant"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs2.aconst"
                        :error-messages="getErrors('aconst', $v.inputs2.aconst)"
                        :disabled="inputs2.is60MA"
                        type="number"
                        step="0.01"
                        @input="$v.inputs2.aconst.$touch()"
                        @blur="$v.inputs2.aconst.$touch()"
                        @change="checkRequired('OS', inputs2.aconst)"
                      />
                    </v-col>
                    <v-col cols="6" md="4">
                      <v-text-field
                        label="Target refraction (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs2.target"
                        :error-messages="getErrors('target', $v.inputs2.target)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs2.target.$touch()"
                        @blur="$v.inputs2.target.$touch()"
                        @change="checkRequired('OS', inputs2.target)"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" md="3">
                      <v-select
                        label="Vitreous type"
                        :items="$parent.vitreousItems"
                        item-text="type"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs2.vitreousType"
                        return-object
                        readonly
                        @change="vitreousTypeUpdated('nvitOS', inputs2.vitreousType)"
                        @blur="vitreousTypeUpdated('nvitOS', inputs2.vitreousType)"
                      />
                    </v-col>
                    <v-col cols="6" md="3">
                      <v-tooltip bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Custom vitreous"
                            outlined
                            class="iol-v-text-field"
                            v-model="inputs2.nvit"
                            :error-messages="getErrors('nvit', $v.inputs2.nvit)"
                            :disabled="inputs2.isVitreous"
                            type="number"
                            step="0.0001"
                            @input="$v.inputs2.nvit.$touch()"
                            @blur="$v.inputs2.nvit.$touch()"
                            @change="checkRequired('OS', inputs2.nvit)"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>Enter the refractive index of the material (ex. silicon oil)</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row class="justify-space-between">
                    <v-col cols="6" md="5">
                      <v-text-field
                        label="AL (mm)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs2.AL"
                        :error-messages="getErrors('AL', $v.inputs2.AL)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs2.AL.$touch()"
                        @blur="$v.inputs2.AL.$touch()"
                        @change="checkRequired('OS', inputs2.AL)"
                      />
                      <v-text-field
                        label="K1 (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs2.K1"
                        :error-messages="getErrors('K1', $v.inputs2.K1)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs2.K1.$touch()"
                        @blur="$v.inputs2.K1.$touch()"
                        @change="checkRequired('OS', inputs2.K1)"
                      />
                      <v-text-field
                        label="K2 (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs2.K2"
                        :error-messages="getErrors('K2', $v.inputs2.K2)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs2.K2.$touch()"
                        @blur="$v.inputs2.K2.$touch()"
                        @change="checkRequired('OS', inputs2.K2)"
                      />
                      <v-text-field
                        label="ACD (mm)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs2.ACD"
                        :error-messages="getErrors('ACD', $v.inputs2.ACD)"
                        type="number"
                        step="0.01"
                        @input="$v.inputs2.ACD.$touch()"
                        @blur="$v.inputs2.ACD.$touch()"
                        @change="checkRequired('OS', inputs2.ACD)"
                      />
                    </v-col>
                    <v-col cols="6" md="5">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-text-field
                          label="LT (mm)"
                          outlined
                          class="iol-v-text-field"
                          v-model="inputs2.LT"
                          :error-messages="getErrors('LT', $v.inputs2.LT)"
                          type="number"
                          step="0.01"
                          @input="$v.inputs2.LT.$touch()"
                          @blur="$v.inputs2.LT.$touch()"
                        />
                        <v-text-field
                          label="CCT (µm)"
                          outlined
                          class="iol-v-text-field"
                          v-model="inputs2.CCT"
                          :error-messages="getErrors('CCT', $v.inputs2.CCT)"
                          type="number"
                          step="0"
                          @input="$v.inputs2.CCT.$touch()"
                          @blur="$v.inputs2.CCT.$touch()"
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-sheet>
          <v-row>
            <v-col cols="12">
              <v-divider />
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn @click="submit" color="#3B4256" class="white--text" x-large
                >Calculate</v-btn
              >
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" class="text-center">
              <a @click="clear" :style="$parent.resetstyle">Reset</a>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-main>
  </v-app>
</template>

<style>
.v-application .primary--text {
  color: #3b4256 !important;
  caret-color: #3b4256 !important;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  requiredIf,
  minValue,
  between
} from 'vuelidate/lib/validators'
import { solve, dualSolve, solveOS } from '@/graphql/queries/solve.gql'
import Logout from '@/components/Logout.vue'

let omitNull = obj => {
  let copy = { ...obj }
  Object.keys(copy)
    .filter(k => copy[k] === null)
    .forEach(k => delete copy[k])
  return copy
}

export default {
  mixins: [validationMixin],
  components: {
    Logout
  },
  validations () {
    return {
      inputs: {
        nvit: {
          required: requiredIf(function (form) {
            return this.inputs.side
          }),
          between: between(1.2, 1.5)
        },
        AL: {
          required: requiredIf(function (form) {
            return this.inputs.side
          }),
          between: between(17, 40)
        },
        ACD: {
          required: requiredIf(function (form) {
            return this.inputs.side
          }),
          between: between(1, 6)
        },
        K1: {
          required: requiredIf(function (form) {
            return this.inputs.side
          }),
          between: between(30, 60)
        },
        K2: {
          required: requiredIf(function (form) {
            return this.inputs.side
          }),
          between: between(30, 60),
          minValue: minValue(this.inputs.K1)
        },
        LT: { between: between(2.5, 8) },
        CCT: { between: between(350, 700) },
        target: {
          required: requiredIf(function (form) {
            return this.inputs.side
          }),
          between: between(-4, 1.5)
        },
        aconst: {
          required: requiredIf(function (form) {
            return this.inputs.side && !this.inputs.is60MA
          }),
          between: between(116.5, 121)
        }
      },
      inputs2: {
        nvit: {
          required: requiredIf(function (form) {
            return this.inputs2.side
          }),
          between: between(1.2, 1.5)
        },
        AL: {
          required: requiredIf(function (form) {
            return this.inputs2.side
          }),
          between: between(17, 40)
        },
        ACD: {
          required: requiredIf(function (form) {
            return this.inputs2.side
          }),
          between: between(1, 6)
        },
        K1: {
          required: requiredIf(function (form) {
            return this.inputs2.side
          }),
          between: between(30, 60)
        },
        K2: {
          required: requiredIf(function (form) {
            return this.inputs2.side
          }),
          between: between(30, 60),
          minValue: minValue(this.inputs2.K1)
        },
        LT: { between: between(2.5, 8) },
        CCT: { between: between(350, 700) },
        target: {
          required: requiredIf(function (form) {
            return this.inputs2.side
          }),
          between: between(-4, 1.5)
        },
        aconst: {
          required: requiredIf(function (form) {
            return this.inputs2.side && !this.inputs2.is60MA
          }),
          between: between(116.5, 121)
        }
      }
    }
  },
  mounted () {
    if (this.$route.query.ocr) {
      this.$v.inputs.$touch()
      this.$v.inputs2.$touch()
    }
    this.inputs = { ...omitNull(this.inputs), ...omitNull(this.ginputs) }
    this.inputs2 = { ...omitNull(this.inputs2), ...omitNull(this.ginputs2) }
  },
  data: () => {
    let ret = {
      inputs: {
        patientID: '',
        keratometricIndex: 1.3375,
        biometer: { type: 'Other', text: 'Other' },
        side: true,
        IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        aconst: 118.9,
        is60MA: false,
        isVitreous: true,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        target: '',
        AL: '',
        K1: '',
        K2: '',
        ACD: '',
        LT: '',
        CCT: ''
      },
      inputs2: {
        patientID: '',
        keratometricIndex: 1.3375,
        biometer: { type: 'Other', text: 'Other' },
        side: true,
        // IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        // aconst: 118.9,
        is60MA: false,
        isVitreous: true,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        target: '',
        AL: '',
        K1: '',
        K2: '',
        ACD: '',
        LT: '',
        CCT: ''
      },
      inputs_default: {
        patientID: '',
        keratometricIndex: 1.3375,
        biometer: { type: 'Other', text: 'Other' },
        side: true,
        IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        aconst: 118.9,
        is60MA: false,
        isVitreous: true,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        target: '',
        AL: '',
        K1: '',
        K2: '',
        ACD: '',
        LT: '',
        CCT: ''
      }
    }
    // if (process.env.NE_ENV !== 'production') {
    //   ret.inputs = {
    //     ...ret.inputs,
    //     patientID: 'testid',
    //     keratometricIndex: 1.3315,
    //     vitreousType: { type: 'Vitreous', value: 1.336 },
    //     biometer: { type: 'Other', text: 'Other' },
    //     AL: 29.34,
    //     ACD: 3.24,
    //     K1: 43.54,
    //     K2: 44.23,
    //     LT: 4.12,
    //     CCT: 534,
    //     target: 0.5,
    //     IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
    //     aconst: 118.9
    //   }
    //   ret.inputs2 = {
    //     ...ret.inputs2,
    //     AL: 22,
    //     ACD: 4,
    //     K1: 33,
    //     K2: 34,
    //     LT: 4,
    //     CCT: 530,
    //     target: 1,
    //     IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
    //     aconst: 118.9
    //   }
    // }
    return ret
  },

  computed: {
    ...mapState(['appHeader', 'ginputs', 'ginputs2'])
  },

  methods: {
    getErrors (name, model) {
      const errors = []
      if (!model.$dirty) return errors
      switch (name) {
        case 'nvit':
        case 'AL':
        case 'ACD':
        case 'K1':
        case 'target':
          !model.required && errors.push('required')
          !model.between && errors.push('value out of bounds')
          break
        case 'aconst':
          !model.required && errors.push('required')
          !model.between && errors.push('value out of bounds')
          break
        case 'K2':
          !model.required && errors.push('required')
          !model.between && errors.push('value out of bounds')
          break
        case 'LT':
        case 'CCT':
          model !== '' && !model.between && errors.push('value out of bounds')
          break
        default:
          break
      }
      return errors
    },
    isODEmpty () {
      return (
        (this.inputs.AL === '') && (this.inputs.ACD === '') && (this.inputs.K1 === '') &&
        (this.inputs.K2 === '')
      )
    },
    isOSEmpty () {
      return (
        (this.inputs2.AL === '') && (this.inputs2.ACD === '') && (this.inputs2.K1 === '') &&
        (this.inputs2.K2 === '')
      )
    },
    checkRequired (side, model) {
      if (side === 'OD') {
        if (model !== '') {
          this.inputs.side = true
        } else {
          this.inputs.side = !this.isODEmpty()
        }
      }
      if (side === 'OS') {
        if (model !== '') {
          this.inputs2.side = true
        } else {
          this.inputs2.side = !this.isOSEmpty()
        }
      }
      if (this.inputs.side && this.isOSEmpty()) {
        this.inputs2.side = false
      }
      if (this.inputs2.side && this.isODEmpty()) {
        this.inputs.side = false
      }
      if (!this.inputs.side && !this.inputs2.side) {
        this.inputs.side = true
        this.inputs2.side = true
      }
    },
    IOLModelUpdated (input, model) {
      if (model.type === '60MA') {
        input.is60MA = true
      } else {
        input.is60MA = false
      }
      input.aconst = model.aconst
    },
    vitreousTypeUpdated (name, model) {
      switch (name) {
        case 'nvitOD':
          this.inputs.nvit = model.value
          if (model.type === 'Vitreous') {
            this.inputs.isVitreous = true
          } else {
            this.inputs.isVitreous = false
          }
          break
        case 'nvitOS':
          this.inputs2.nvit = model.value
          if (model.type === 'Vitreous') {
            this.inputs2.isVitreous = true
          } else {
            this.inputs2.isVitreous = false
          }
          break
      }
    },
    focusFirstError (component = this) {
      if (component.hasError) {
        component.focus()
        return true
      }

      let focused = false

      component.$children.some(childComponent => {
        focused = this.focusFirstError(childComponent)
        return focused
      })

      return focused
    },
    submit () {
      this.$v.inputs.$touch()
      this.$v.inputs2.$touch()
      this.$nextTick(() => {
        if (this.focusFirstError()) {
          return
        }
        Object.keys(this.inputs).forEach(
          k => (this.inputs[k] = this.inputs[k] === '' ? null : this.inputs[k])
        )
        Object.keys(this.inputs2).forEach(
          k => (this.inputs2[k] = this.inputs2[k] === '' ? null : this.inputs2[k])
        )

        this.inputs2['patientID'] = this.inputs['patientID']
        this.inputs2['biometer'] = this.inputs['biometer']
        this.inputs2['keratometricIndex'] = this.inputs['keratometricIndex']

        const inputs = { ...omitNull(this.inputs) }
        const inputs2 = { ...omitNull(this.inputs2) }
        this.$store.dispatch('updateInputs', this.inputs)
        this.$store.dispatch('updateInputs2', this.inputs2)

        inputs['biometer'] = inputs['biometer'].type // send biometer type
        inputs2['biometer'] = inputs2['biometer'].type // send biometer type
        if (inputs['IOLModel']) {
          inputs['IOLModel'] = inputs['IOLModel'].type // send IOLModel type
        }
        if (inputs2['IOLModel']) {
          inputs2['IOLModel'] = inputs2['IOLModel'].type // send IOLModel type
        }

        delete inputs['patientID'] // do not send patientID
        delete inputs['vitreousType'] // do not send vitreousType
        delete inputs['isVitreous'] // do not send isVitreous
        delete inputs['is60MA'] // do not send is60MA
        delete inputs2['patientID'] // do not send patientID
        delete inputs2['vitreousType'] // do not send vitreousType
        delete inputs2['isVitreous'] // do not send isVitreous
        delete inputs2['is60MA'] // do not send is60MA

        // delete inputs from complex and second
        delete inputs['isNonPhysioCornea']
        delete inputs['isICL']
        delete inputs['isRK']
        delete inputs['isCornealLaser']
        delete inputs['cornealLaserType']
        delete inputs['laserAmount']
        delete inputs['preLvcArc']

        delete inputs['isSecond']
        delete inputs['AL2']
        delete inputs['K12']
        delete inputs['K22']
        delete inputs['ACD2']
        delete inputs['LT2']
        delete inputs['CCT2']
        delete inputs['power2']
        delete inputs['SE2']
        delete inputs['aconst2']
        delete inputs['isCornealLaser2']
        delete inputs['cornealLaserType2']
        delete inputs['laserAmount2']
        delete inputs['isICL2']
        delete inputs['preLvcArc2']

        if (this.inputs.side && this.inputs2.side) {
          this.$apollo
            .query({
              query: dualSolve,
              variables: {
                inputs,
                inputs2
              }
            })
            .then(result => {
              this.$store.dispatch('setResults', result.data.solveOD)
              this.$store.dispatch('setResults2', result.data.solveOS)
              this.$router.push('result_regular')
            })
            .catch(e => {
              this.$raven.captureException(e)
            })
        } else if (this.inputs.side) {
          this.$apollo
            .query({
              query: solve,
              variables: {
                inputs
              }
            })
            .then(result => {
              this.$store.dispatch('setResults', result.data.solve)
              this.$router.push('result_regular')
            })
            .catch(e => {
              this.$raven.captureException(e)
            })
        } else if (this.inputs2.side) {
          this.$apollo
            .query({
              query: solveOS,
              variables: {
                inputs2
              }
            })
            .then(result => {
              this.$store.dispatch('setResults2', result.data.solve)
              this.$router.push('result_regular')
            })
            .catch(e => {
              this.$raven.captureException(e)
            })
        }
      })
    },
    clear () {
      Object.keys(this.inputs).forEach(k => (this.inputs[k] = this.inputs_default[k]))
      Object.keys(this.inputs2).forEach(k => (this.inputs2[k] = this.inputs_default[k]))
      // Object.keys(this.inputs).forEach(k => (this.inputs[k] = this.inputs_default[k] !== null ? this.inputs_default[k] : ''))
      // Object.keys(this.inputs2).forEach(k => (this.inputs2[k] = this.inputs_default[k] !== null ? this.inputs_default[k] : ''))
      this.$refs.first.focus()
    },
    ...mapActions(['updateInputs', 'setResults', 'updateInputs2', 'setResults2', 'setAuthUser'])
  }
}
</script>
